<template>
	<div>
		<CustomTable
			id_table="qualif_config"
			ref="table"
			:items="qualifications"
			:busy.sync="table_busy"
			primaryKey="qualification_id"
		/>
		<ModalQualification 
			ref="modalQualification"
			:refresh_table.sync="refresh_table"
			:type="qualification_type"
			:qualification="selected"
		/>
	</div>
</template>

<script>
import Qualification from '@/mixins/Qualification'
import Navigation from "@/mixins/Navigation.js"

export default {

	name: 'QualificationListe',
	mixins: [Qualification, Navigation],
	props: {
		qualificationtype_libelle: {
			type: String,
			default: 'tiers'
		}
	},
	data() { 
		return {
			table_busy: true,
			transformer: null,
			qualification_type: null,
	        qualifications: [],
			events_tab: {
	            'TableAction::goToAddQualification': () => { 
	            	this.selected = null
	            	this.openModalQualification() 
	            },
	            'TableAction::goToEditQualification': (qualif) => { 
	            	this.selected = qualif[0]
	            	this.openModalQualification() 
	            },
	            'TableAction::goToDeleteQualification': this.deleteQualification
	        },
	        selected: {},
	        refresh_table: false,
	    }
	},
	created() {
		this.reloadQualifications()
	},
	methods: {
		openModalQualification() {
			this.$refs.modalQualification.openModal()
		},
		async reloadQualifications() {
			this.selected = {}
			if (this.qualificationtype_libelle) {
				this.table_busy = true
				this.qualification_type = await this.getQualificationType(this.qualificationtype_libelle)
				this.qualifications = await this.getUserQualifications(this.qualification_type.qualificationtype_id)
				this.table_busy = false

				if(this.$refs.table) this.$refs.table.refreshTable()
			}
		},

		async deleteQualification(qualifs) {
			let tab_promises = []
			for(let i in qualifs) {
				tab_promises.push(this.deleteQualif(qualifs[i].qualification_id))
			}

			Promise.all(tab_promises)
			.then(() => {
				this.reloadQualifications()
				this.successToast("toast.info_save_succes")
			})
			.catch(() => {
				this.failureToast()
			})
		},
	},
	computed: {
		filters() {
			return {
				qualification_type: {
					column: 'qualification_type',
					operator: 'isEqualTo',
					value: this.qualification_type ? this.qualification_type.qualificationtype_id : 0
				}
			}
		}
	},
	watch: {
		qualificationtype_libelle() {
			this.reloadQualifications()
		},
		refresh_table() {
			this.reloadQualifications()
		}
	},
	components: {
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		ModalQualification: () => import('@/components/Qualification/ModalQualif')
	}
}
</script>
